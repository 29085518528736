import $ from "jquery";
import { hcp,professional } from "./hcp.js";

import _ from "underscore";
import validate from 'validate.js';

let screenshotReady, imgslider, formThanks;

let speed = 1000; //milliseconds

var i = 0;
const observerOptions = {
  rootMargin: "-100px 0px 0px 0px",
  threshold: 1.0,
};
var progressBars = document.querySelectorAll(
  ".animated-progress .animated-progress-bar"
);
if (i == 0) {
  i = 1;
  progressBars.forEach(function (elem) {
    var width = 0;
    let contentBar = elem.querySelector(".percentige-content");
    let cont = contentBar.innerText;
    let percentige = elem.dataset.progress;
    let displayPercent = elem.dataset.displayPercent;
    var id = setInterval(frame, speed / percentige);

    function frame() {
      if (width >= percentige) {
        clearInterval(id);
        i = 0;
      } else {
        width++;
        elem.style.width = `${width}%`;
        elem.innerHTML = displayPercent
          ? `<span class="percentige">${Math.round(
              (displayPercent / 100) * (width / (percentige / 100))
            )}%</span><span class="percentige-text">${cont}</span>`
          : `${width}%`;
      }
    }
  });
}

// Register IntersectionObserver
const io = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    let elem = entry.target;
    var v = elem.classList.contains("active");

    if (entry.intersectionRatio > 0 && v) {
      let progressCircle = elem.querySelector(".active-progress-circle");
      let spanCircle = elem.querySelector("span");
      let radius = progressCircle.getAttribute("r");
      let circumference = Math.PI * (radius * 2);
      let percent = spanCircle.dataset.progress;
      let percentige = (circumference / 100) * percent;
      let left = percent == 100 ? 0 : circumference - percentige;
      progressCircle.style.strokeDasharray = circumference;
      progressCircle.style.strokeDashoffset = circumference;
      let progress = circumference;
      var id = setInterval(frame, speed / percentige);
      function frame() {
        if (progress <= left) {
          clearInterval(id);
          v = 0;
        } else {
          progress--;
          progressCircle.style.strokeDashoffset = Math.round(progress);
          let percentDisplay =
            (circumference - progress) / (circumference / 100);
          spanCircle.innerHTML =
            percentDisplay >= percent
              ? `${percent}%`
              : `${Math.round(
                  (circumference - progress) / (circumference / 100)
                )}%`;
        }
      }
      elem.classList.remove("active");
    }
  });
}, observerOptions);

// Declares what to observe, and observes its properties.
const boxElList = document.querySelectorAll(".progress-circle-box.active");
if (boxElList) {
  boxElList.forEach((el) => {
    io.observe(el);
  });
}

// Register IntersectionObserver
const vueIO = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (
      entry.intersectionRatio > 0 &&
      !entry.target.classList.contains("active")
    ) {
      // Add 'active' class if observation target is inside viewport
      entry.target.classList.add("active");
    }
  });
}, observerOptions);
// Declares what to observe, and observes its properties.
const vueObserveList = document.querySelectorAll(".vue-observe");
if (vueObserveList) {
  vueObserveList.forEach((el) => {
    vueIO.observe(el);
  });
}

// Mobile menu
const isiHeaderLink = document.querySelector("._isi-header-link");
const openMobileMenuButton = document.getElementById("open-menu-button");
openMobileMenuButton.addEventListener("click", function (e) {
  console.log("clicked");
  document.body.classList.toggle("menu-expanded");
  isiHeaderLink.addEventListener(
    "click",
    function (e) {
      document.body.classList.toggle("menu-expanded");
    },
    { once: true }
  );
});
const closeMobileMenuButton = document.getElementById("close-menu-button");
closeMobileMenuButton.addEventListener("click", function (e) {
  document.body.classList.toggle("menu-expanded");
});

const screenshot_functions = () => {
  const get_URL_params = (sParam) => {
    //console.log('sParam',sParam)
    let sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName;

    for (let i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  };

  screenshotReady = get_URL_params("screenshot");
  imgslider = get_URL_params("imgslide");
  formThanks = get_URL_params("thanks");

  if (screenshotReady) {
    console.log("mobile_screen_mod ----");
    $("html, body").animate({ scrollTop: 0 }, 1000);
    $("body").css("overflow", "initial").addClass("screenshot");
  }

  if (imgslider) {
    console.log("img slider - set height to 1000px");
    window.scrollTo({ top: 340 });
    $("body").css("overflow", "initial").addClass("imgslide");
  }

  if (formThanks) {
    console.log("thanks");
    $("body").addClass("thanks");
  }
};

window.addEventListener(
  "load",
  () => {
    document.body.classList.add("page-loaded");
    screenshot_functions();
    professional();
    hcp();
  },
  { once: true }
);

// Form
document.addEventListener("DOMContentLoaded", function () {
  const phonePattern =
    /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9][0-8][0-9])\s*\)|([2-9][0-8][0-9])\s*)(?:[.-]\s*)?([2-9][0-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?)$/;
  const successModal = document.getElementById("appointmentSucessModal");

  // These are the constraints used to validate the form
  var constraints = {
    "first-name": {
      // Email is required
      presence: {
        message: "^Please enter a first name",
      },
    },
    "last-name": {
      // Email is required
      presence: {
        message: "^Please enter a last name",
      },
    },
    "phone-number": {
      // Email is required
      presence: {
        message: "^Please enter a valid phone number",
      },
      format: {
        //pattern: '^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$'
        pattern: phonePattern,
      },
    },
    email: {
      // Email is required
      presence: {
        message: "^Please enter a valid email address",
      },
      // and must be an email (duh)
      email: {
        message: "Please enter a valid email address",
      },
    },
    "postal-code": {
      // Zip is required
      presence: {
        message: "^Can't be blank",
      },
      format: {
        pattern: "\\d{5}",
        message: "^Please enter a valid 5 digit zip code",
      },
    },
    region: {
      presence: {
        message: "^Can't be blank",
      },
    },
    specialty: {
      presence: {
        message: "^Please enter your specialty",
      },
    },
    "connect-with": {
      // Email is required
      presence: {
        message: "^Please choose an option",
      },
    },
    contacted: {
      // Email is required
      presence: {
        message: "^Please choose an option",
      },
    },
  };

  // Hook up the form so we can prevent it from being posted
  var form = document.querySelector("form#appointment-form");
  form.addEventListener("submit", function (ev) {
    ev.preventDefault();
    handleFormSubmit(form, ev);
  });

  // Hook up the inputs to validate on the fly
  var inputs = document.querySelectorAll("input, textarea, select");
  for (var i = 0; i < inputs.length; ++i) {
    inputs.item(i).addEventListener("change", function (ev) {
      var errors = validate(form, constraints) || {};
      showErrorsForInput(this, errors[this.name]);
    });
  }

  function handleFormSubmit(form, event, input) {
    // validate the form against the constraints
    var errors = validate(form, constraints);
    // then we update the form to reflect the results
    showErrors(form, errors || {});
    if (!errors) {
        const mapping = {
            'email': 'SRV_Email_Address__c',
            'first-name': 'SRV_First_Name__c',
            'last-name': 'SRV_Last_Name__c',
            'specialty': 'SRV_Specialty__c',
            'region': 'SRV_State__c',
            'postal-code': 'SRV_Zip_Code__c',
            'phone-number': 'SRV_Phone__c',
            'connect-with': 'SRV_Rep_Type__c',
            'contacted-email': 'SRV_Contact_by_Email__c',
            'contacted-phone': 'SRV_Contact_by_Phone__c'
        };
        const elements = event.target.elements;
        console.log('elements:', elements);
        console.log('mapping:', mapping);
        const cForm = {
            // the institution field is only present for Sign up for Updates.
            SRV_Web_Form_Type__c: 'institution' in elements ? 'Sign up for Updates' : 'Request a Rep'
        };
        Object.getOwnPropertyNames(elements).forEach(key => {
            if (key in mapping) {
                console.log('key:', key);
                console.log('element value:', elements[key]?.value); // Log the element value
                if (key == 'contacted-email' || key == 'contacted-phone') {
                    cForm[mapping[key]] = document.getElementById(key).checked;
                } else {
                    cForm[mapping[key]] = elements[key]?.value; // Use optional chaining to avoid undefined errors
                }
            }
        });
        console.log('form', cForm);
        SRV_Oncaspar.saveWebForm(cForm, function (response, event) {
            if (event.status) {
                if (response.status === 200) {
                    console.log('success...');
                    showSuccess();
                } else {
                    alert(response.msg);
                }
            }
        });
    }
  }

  // Updates the inputs with the validation errors
  function showErrors(form, errors) {
    // We loop through all the inputs and show the errors for that input
    _.each(
      form.querySelectorAll("input[name], select[name]"),
      function (input) {
        // Since the errors can be null if no errors were found we need to handle
        // that
        showErrorsForInput(input, errors && errors[input.name]);
      }
    );
  }

  // Shows the errors for a specific input
  function showErrorsForInput(input, errors) {
    // This is the root of the input
    var formGroup = closestParent(input.parentNode, "form-group"),
      // Find where the error messages will be insert into
      messages = formGroup.querySelector(".messages");
    // First we remove any old messages and resets the classes
    resetFormGroup(formGroup);
    // If we have errors
    if (errors) {
      // we first mark the group has having errors
      formGroup.classList.add("has-error");
      // then we append all the errors
      _.each(errors, function (error) {
        addError(messages, error);
      });
    } else {
      // otherwise we simply mark it as success
      formGroup.classList.add("has-success");
    }
  }

  // Recusively finds the closest parent that has the specified class
  function closestParent(child, className) {
    if (!child || child == document) {
      return null;
    }
    if (child.classList.contains(className)) {
      return child;
    } else {
      return closestParent(child.parentNode, className);
    }
  }

  function resetFormGroup(formGroup) {
    // Remove the success and error classes
    formGroup.classList.remove("has-error");
    formGroup.classList.remove("has-success");
    // and remove any old messages
    _.each(formGroup.querySelectorAll(".help-block.error"), function (el) {
      el.parentNode.removeChild(el);
    });
  }

  // Adds the specified error with the following markup
  // <p class="help-block error">[message]</p>
  function addError(messages, error) {
    var block = document.createElement("p");
    block.classList.add("help-block");
    block.classList.add("error");
    block.innerText = error;
    messages.appendChild(block);
  }

  function showSuccess() {
    successModal.style.display = "block";
  }

  let closeButtons = document.querySelectorAll(".closeModal");
  closeButtons.forEach(function (elem) {
    elem.addEventListener("click", function () {
      successModal.style.display = "none";
      form.reset();
    });
  });
});
